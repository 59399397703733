.project-images {
  display: flex;
  flex-wrap: wrap;
}

.project-image {
  width: 100px;
  height: 120px;
  text-align: center;
  margin: 0.5em;

  a, a:link, a:visited {
    text-decoration: none;
    color: inherit;
  }

  .name {
    max-height: 3em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: break-spaces;
  }

  .thumb {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5em;
  }

  &:hover .actions {
    opacity: 1;
  }
}