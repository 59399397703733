$enable-shadows: false;
$jumbotron-padding: 1.5rem;


html, body {
    height: 100%;
}
.html-title-buttons{
  float: right;
}

.custom_pagination {
    width: 100%;
    border-top: 1px solid #e8f0f3;
    padding-right: 10px;
}
.custom_pagination > nav > span {
    padding: 0 4px;
    color: #656766;
}

.custom_pagination > nav > span > a,
.custom_pagination > nav > span > span {
    color: #656766;
}

.custom_pagination > nav > span.current {
    color: white;
    display: inline-block;
    background-color: #007ffe;
    border-radius: 3px;
    width: 22px;
    text-align: center;
}
.search_form{
    padding: 0 14px;
}
.content-header{
    height: 40px !important;
}
