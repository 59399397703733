@import "bootstrap/scss/bootstrap";

// .table {
//   font-size: 0.8em;
// }

html {
  font-size: 13px;
}

html.login {
  font-size: 16px;
}
