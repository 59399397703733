#login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding-bottom: 100px;

  #main-container {
    width: 420px;

    nav.header {
      position: fixed !important;
      top: 0;
      left: 0;
      right: 0;
    }


    h1 {
      width: 100%;
      text-align: center;
    }

    .switch-box {
      color: var(--scada-white-color-lighter-2);
      font-weight: 400;
      font-size: 14px;
    }

    .form-item {
      position: relative;

      label {
        position: absolute;
        top: 50%;
        left: 32px;
        transform: translateY(-50%);
        color: rgba(41, 41, 41, 0.55);
        transition: all .25s ease-in-out;
      }

      input:-webkit-autofill {
        -webkit-box-shadow:0 0 0 1000px #F5F5F5 inset !important;
      }

      &.error {
        input {
          outline: 1px solid var(--scada-danger-color) !important;
        }
      }

      .error-notice {
        position: absolute;
        color: var(--scada-danger-color);
        font-size: 12px;
        font-weight: 400;
      }

      input:focus ~ label {
        top: 18%;
        font-size: 12px;
      }

      input:not(:placeholder-shown) ~ label {
        top: 18%;
        font-size: 12px;
      }

      input {
        width: 100%;
        height: 48px;
        padding-left: 32px;
        background: #F5F5F5;
        border-radius: var(--scada-common-border-radius);
        border: none;
        outline: none !important;

        &:focus, &:hover, &:active {
          outline: 2px solid var(--scada-primary-color) !important;
        }
      }
    }
  }

  .browser-icon {
    fill: var(--scada-light-color);
  }
}
