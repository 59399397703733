@import url('https://scada.foresight-soft.com/google-fonts/scada-font.css');
@import "../../assets/stylesheets/index";

.enumeration-text-import-area {
  position: relative;
  background-color: #e1e1e1;
  margin-top: -40px;
  padding: 10px;
  border: 1px solid #e1e1e1;
}

button, input, i, a {
  -webkit-app-region: no-drag;
}


#custom-dialog {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 9999;
  width: 400px;
  height: 200px;
  box-shadow: 0 0 3px #292929;
  background: #ffffff;
  padding: 24px;
  border-radius: var(--scada-common-border-radius);

  .btn.btn-primary {
    background: var(--scada-primary-color);
    color: var(--scada-light-color);
  }
}
