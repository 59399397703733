.downloads-page {
  nav.header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
  }

  main {
    margin: 90px 60px;
  }

  h1 {
    font-size: 42px;
    font-weight: 700;
  }

  h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .downloads-main-container {
    display: flex;
    justify-content: space-between;
    margin: 24px 128px;

    .downloads-container {

    }

    .download-card {
      min-width: 333px;
      width: 25vw;
      aspect-ratio: 20 / 9;
      display: flex;
      background: var(--scada-light-color);
      justify-content: space-between;
      align-items: center;
      padding: 33px 24px;
      cursor: pointer;
      transition-property: box-shadow, background, fill;
      transition-duration: .15s;
      transition-timing-function: ease-in-out;
      font-style: italic;
      font-weight: 712;
      font-size: 30px;
      line-height: 36px;
      border: 1px solid var(--scada-primary-color-lighter-8);
      border-radius: 6px;
      text-decoration: none;
      color: inherit;

      &:hover {
        background: var(--scada-primary-color-lighter-8);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

        .download_l {
          path, rect {
            fill: var(--scada-dark-color);
          }
        }
      }

      .os-icon {
        width: 25%;
      }
    }

    .versions-container {
      margin-top: 24px;
      position: relative;
      font-style: italic;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: rgba(41, 41, 41, 0.85);

      .versions-dropdown {
        width: 190px;
        background: #f7f7f7;
        position: absolute;
        display: none;
        padding: 12px;
        font-size: 16px;

        .version-select-item {
          padding: 0 12px;
          line-height: 28px;

          &:hover {
            background: #29292926;
          }
        }
      }

      &:hover {
        .versions-dropdown {
          display: block;
        }
      }
    }
  }

  .downloads-others-container {
    margin: 108px;

    .downloads-tool-container {
      display: flex;
      cursor: pointer;
      text-decoration: none;
      color: var(--bs-body-color);

      &:hover {
        background: var(--scada-primary-color-lighter-8);
      }

      .download_l {
        path, rect {
          fill: var(--scada-primary-color);
        }
      }

      &:not(:first-child) {
        margin-top: 24px;
      }

      .downloads-tool-infos {
        flex: 1;

        .downloads-tool-title {
          font-weight: 500;
          font-size: 30px;
          line-height: 36px;
        }

        .downloads-tool-sub {
          font-style: italic;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }


  .split-hr {
    margin: 44px;
    width: calc(100% - 88px);
    background: var(--scada-primary-color-lighter-1);
    height: 6px;
    border-radius: 3px;
  }

}

