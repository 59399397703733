@use "sass:math";

.top-toolbar {
  margin-top: $spacer;
  margin-bottom: $spacer;

  & > div {
    display: flex;
    align-items: center;
  }
  
  .index_search_form {
    display: flex;
    margin-right: 10px;
    
    > * {
      margin-right: math.div($spacer, 2);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .search-form-btn {
    display: flex;
  }

  .form-control, .form-control-plaintext {
    width: auto;
  }
}
