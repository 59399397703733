// @import "~bootstrap"

#navbar-box {
  flex-basis: auto;
}

#navbar-box .dropdown-menu .nav-link:not(.active) {
  color: var(--bs-dark);
}

.table td:last-child a {
  display: inline-block;
}

.container > h3:first-child {
  margin-top: 1rem;
}

.button_to .nav-link {
  border: none;
}

footer {
  background: var(--bs-light);
  color: var(--bs-gray);
  padding: 3em 0;
  min-height: 200px;
}
