$base-margin: 12px;

body::-webkit-scrollbar{
  width: 0;
}

.btn-primary {
  border-color: unset !important;
}

nav.header {
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  background: var(--scada-primary-color);
  height: 32px;
  padding: 0 var(--scada-edge-sm);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--scada-fs-md);

  .btn {
    -webkit-app-region: no-drag;
  }

  .left-container, .right-container, .search-input {
    -webkit-app-region: no-drag;
  }

  .left-container {
    display: flex;
    height: 100%;
    gap: 1px;
    margin: 0 10px;
    align-items: center;

    .btn {
      background: var(--scada-secondary-color) !important;
      border-radius: var(--scada-common-border-radius) !important;
      border-color: var(--scada-secondary-color) !important;
    }

    .logo {
      width: 30px;
    }

    .scada-text {
      width: 83px;
    }
  }

  .search-input {
    text-align: center;
    max-width: 264px;

    input {
      width: 100%;
      background: rgba(41, 41, 41, 0.75);
      color: var(--scada-light-color);
      border-radius: var(--scada-common-border-radius);
      border-width: 0;
      padding: 2px 8px !important;

      &:active, &:hover, &:focus, &:focus-visible {
        border-color: var(--scada-secondary-color) !important;
        outline: var(--scada-secondary-color) !important;
      }
    }
  }

  .right-container {
    .navbar-nav {
      justify-content: flex-end;
    }

    .nav-link {
      -webkit-app-region: no-drag;
    }

    .tri1 {
      position: absolute;
      right: 11px;
      top: -8px;
      width: 0;
      height: 0;
      border-width: 0 8px 8px;
      border-style: solid;
      border-color: transparent transparent var(--scada-primary-color);

      &:after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        top: 1px;
        left: -7px;
        border-width: 0 7px 7px;
        border-style: solid;
        border-color: transparent transparent #ffffff;
      }
    }

    .dropdown-menu {
      // inset: 15px auto auto 0 !important;
      border-radius: var(--scada-common-border-radius);
      border: 1px solid var(--scada-primary-color);

      .dropdown-item {
        padding: var(--scada-edge-sm);
      }

      .dropdown-item:hover {
        background: var(--scada-primary-color);
        color: var(--scada-light-color);
      }
    }

    .nav-link {
      color: var(--scada-dark-color);
      font-size: var(--scada-fs-md) !important;

      .fas {
        position: relative;
        top: 2px;
      }

      &:after {
        display: none;
      }
    }
  }

  .tabs {
    display: flex;
    height: 100%;
    gap: 1px;
    user-select: none;

    .tab {
      display: flex;
      align-items: center;
      padding: 0 5px 0 10px;
      cursor: pointer;
      transition: background-color ease-in-out .25s, color ease-in-out .25s;
      margin-top: 1px;
      border-radius: 3px 3px 0 0;
      color: #2f2f2f;

      .close::after {
        content: "\00D7";
        font-style: normal;
        display: flex;
        width: 20px;
        align-items: center;
        justify-content: center;
        opacity: 0;
      }

      .close:hover {
        // background-color: red;
      }

      &.active {
        background: #AFCED3;
        color: black;
      }

      &:hover {
        background: #CCE2E6;
        color: black;
      }

      &:hover .close::after {
        opacity: 1;
      }


    }
  }
}

.nav-tabs {
  border-bottom: none !important;
  font-size: var(--scada-fs-md) !important;
  margin: var(--scada-edge-bg) 0;

  .nav-link:hover {
    color: var(--scada-primary-color) !important;
  }
}

.nav-link {
  color: rgba(41, 41, 41, 0.65);
  border: none !important;

  &.active {
    color: var(--scada-primary-color) !important;
    border-bottom: 1px solid var(--scada-primary-color) !important;
  }
}

body{
  height: 100vh;
}

#projects-home {
  display: flex;
  flex-direction: column;
  height: 100%;
}

i.go-preferences {
  font-style: italic;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  color: #858ea1;
  width: 16px;
  height: 16px;
  cursor: pointer;
  float: right;
}

i.go-preferences:hover {
  color: #0b5ed7;
  background-color: lightblue;
}


.go-preferences::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 4px;
  height: 4px;
  background: currentColor;
  box-shadow: 0 6px 0, 6px 6px 0, 12px 6px 0, 6px 12px 0, 12px 12px 0, 6px 0 0, 12px 0 0, 0 12px 0;
  border-radius: 22px;
}
.wrap {
  background-color: #fff;
  //border: solid 1px #ddd;
  border-radius: 4px 4px 0 0;
  margin: 48px auto;
  max-width: 1440px;

  .nav-wrap {
    padding: 16px 48px 0;
  }
  .base-container {
    padding: 8px 48px;
    //margin-left: $base-margin;
    .title {
      font-size: 16px;
      color: #495057;
      margin-bottom: 8px;
    }
  }
  hr {
    margin: 0 $base-margin;
  }
  .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, calc(25% - 24px));
    grid-row-gap: 24px;
    grid-column-gap: 24px;
    justify-content: space-between;

    &-item {
      position: relative;
      height: 300px;
      padding: var(--scada-edge-bg);
      border-radius: var(--scada-common-border-radius);
      border: 1px solid rgba(0, 0, 0, 0.25);
      display: flex;
      flex-direction: column;
      cursor: pointer;
      overflow: hidden;
      color: #303133;
      transition: .3s;
      &:hover {
        //box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
        border-color: var(--scada-primary-color);
        border-width: 2px;

        .project-operates {
          opacity: 1;
        }
      }

      .project-preview {
        width: 100%;
        height: 124px;
        background: #c7c8c8;
        border-radius: var(--scada-common-border-radius);
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
          max-width: 100%;
          height: auto;
          object-fit: cover;
        }
      }

      .project-title {
        margin-top: var(--scada-edge-bg);
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .project-infos {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .project-info {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        img.icon {
          width: 16px;
          height: 16px;
          position: relative;
          top: -2px;
        }
      }

      .project-operates {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 32px;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity .25s;

        svg {
          flex: 1;
        }
      }
    }
  }
  .blue-block {
    width: 4px;
    height: 16px;
    background-color: #409eff;
    display: inline-block;
    transform: translateY(3px);
    border-radius: 4px;
    margin-right: 4px;
  }

  .card {
    &-title {
      font-weight: bold;
    }
    &-text {
      margin-bottom: 4px;
    }
  }
  .error-tip {
    margin-top: 60px;
    color: #dc3545;
    background-color: #fef0f0;
    border-color: #fde2e2;
  }
  .page-link {
    cursor: pointer;
  }
  .tab-panel {
    position: relative;
    .search-panel {
      display: flex;
      align-items: center;
      position: absolute;
      right: 12px;
      input {
        width: 200px;
      }
    }
  }
}

.pagination {
  margin-top: 12px;

  .page-item {
    .page-link {
      background: #F5F5F5 !important;
      color: var(--scada-primary-color) !important;
      border-width: 0;
    }

    &.active {
      .page-link {
        background: var(--scada-primary-color) !important;
        color: #ffffff !important;
      }
    }
  }
}

.flex-end {
  width: 100%;
  display: flex;
  justify-content: end;
}


@media screen and (max-width: 900px) {
  .list {
    grid-template-columns: repeat(auto-fill, calc(33% - 24px)) !important;
  }
}


@media screen and (min-width: 1281px) {
  .list {
    grid-template-columns: repeat(auto-fill, calc(20% - 24px)) !important;
  }
}

.tab-content-wrapper {
  flex: 1;

  iframe {
    width: 100%;
    height: 100%;
  }

  .tab-content {
    display: none;

    &.active {
      display: block;
    }
  }
}
