.hmi-component {
  .toolbar {
    display: flex;
    margin: 1rem 0;

    .back-button, .breadcrumb-container {
      padding: 0.5rem;
    }

    .back-button::before {
      content: '◀ '
    }

    .btn {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }

    .breadcrumb {
      margin-bottom: 0;
      margin-left: .5em;
      
    }

    .breadcrumb-container {
      display: flex;
      flex: 1;
      margin: 0 1rem;
      color: $breadcrumb-active-color;
    }

    .right-col {
      display: flex;
      align-items: center;
    }

  }

  .index_search_form {
    display: flex;
    justify-content: flex-end;

    .form-control {
      flex: 0 1 auto;
    }

    [type=date] {
      min-width: 10em;
    }

    .btn, [type=submit] {
      flex: 0 0 auto;
    }

    & > * {
      margin-right: 0.5em;
    }
  }

  .profiler-results {
    display: none;
  }

  .btn-icon {
    display: flex;
    align-items: center;
  }
}
