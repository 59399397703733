@use "sass:math";

$colors: (
        primary: #85B4BC,
        secondary: #9865FC,
        info: #FC4C87,
        warning: #FC8E32,
        success: #6EB061,
        danger: #F25757,
        dark: #292929,
        light: #F5F5F5
);

:root {
  @each $key, $val in $colors {
    --scada-#{$key}-color: #{$val};

    @for $i from 1 through 9 {
      $value: math.div($i, 10);
      --scada-#{$key}-color-lighter-#{10-$i}: #{rgba($val, $value)}
    }
  }

  --scada-fs-lg: 24px;
  --scada-fs-bg: 18px;
  --scada-fs-md: 14px;
  --scada-fs-sm: 12px;

  --scada-edge-bg: 24px;
  --scada-edge-md: 14px;
  --scada-edge-sm: 8px;

  --scada-common-border-radius: 6px;
  --scada-font-family: 'Cooper Hewitt', 'Noto Sans CJK', 'Noto Sans CJK SC', 'Noto Sans SC', 'Noto Sans', sans-serif;
};


body {
  font-family: var(--scada-font-family);
}

h1, .h1 {
  font-size: var(--scada-fs-lg);
}

h2, .h2 {
  font-size: var(--scada-fs-bg);
}

h3, .h3 {
  font-size: var(--scada-fs-md);
}

h4, .h4 {
  font-size: var(--scada-fs-sm);
}


.scada-btn {
  border: none;
  border-radius: var(--scada-common-border-radius);
  color: var(--scada-light-color);
  padding: 6px;
  font-weight: 400;
  font-size: 14px;

  &.primary {
    background: var(--scada-primary-color);
  }

  &.secondary {
    background: var(--scada-secondary-color);
  }
  &.warning {
    background: var(--scada-warning-color);
  }
}

.switch-box {
  display: flex;
  align-items: center;

  input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 30px;
    height: 15px;
    background: grey;
    display: block;
    border-radius: 15px;
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 13px;
    height: 13px;
    background: #fff;
    border-radius: 6px;
    transition: 0.3s;
  }

  input:checked + label {
    background: var(--scada-primary-color);
  }

  input:checked + label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 15px;
  }
}


