@use 'sass:math';

.simple_form {
  .form-inputs {
    margin-top: 2rem;
  }
  
  .form-group{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
  }

  @include media-breakpoint-up(sm) { 
    .col-form-label {
      text-align: right;
    }
  }
  .col-form-label {
    font-weight: bold;
  }

  .col-form-label {
    @include media-breakpoint-up(sm) { 
      width: percentage(math.div(2, 12));
    }
  }

  .col-input-wrapper {
    @include media-breakpoint-up(sm) { 
      width: percentage(math.div(10, 12));
    }
  }
  .col-md-6 {
    .col-form-label {
      @include media-breakpoint-up(sm) { 
        width: percentage(math.div(2, 12));
      }

      @include media-breakpoint-up(md) { 
        width: percentage(math.div(4, 12));
      }
    }
  
    .col-input-wrapper {
      @include media-breakpoint-up(sm) { 
        width: percentage(math.div(10, 12));
      }

      @include media-breakpoint-up(md) { 
        width: percentage(math.div(8, 12));
      }
    }
  }

  .form-check {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .form-check-inline {
    display: inline-block;
    margin-top: calc(0.375rem + 1px);
    height: auto;
  }
}


.form-actions {
  @extend .bg-light;
  padding: 1rem;
  border-top: 1px solid $input-border-color;
  border-bottom: 1px solid $input-border-color;
}

.hidden{
  visibility: hidden;
  display: none !important;
}

.check_boxes_wrapper {
  padding-bottom: calc(0.375rem + 1px);
}
