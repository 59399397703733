:root {
    /*--scada-primary-color: #85B4BC;*/
    /*--scada-secondary-color: #9865FC;*/
    /*--scada-info-color: #FC4C87;*/
    /*--scada-warning-color: #FC8E32;*/
    /*--scada-success-color: #6EB061;*/
    /*--scada-danger-color: #F25757;*/
    /*--scada-black: #292929;*/
    /*--scada-white: #F5F5F5;*/

}
