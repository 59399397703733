.hmi-component {
  .new_user_key {
    .project_user_operation_password, .project_user_operation_password_confirmation {
      display: none;
    }

    &[data-operation-type="0"] {
      .project_user_operation_password {
        display: flex;
      }
    }

    &[data-operation-type="1"] {
      .project_user_operation_password, .project_user_operation_password_confirmation {
        display: flex;
      }
    }

  }
}
