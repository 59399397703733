.chrome-scroll-fix { /* body*/
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;

    .chrome-scroll-fix-scroller {
        height: 100%;
        box-sizing: border-box;
        overflow: auto;
    }
}


