table.audit-trail {
  .signature {
    word-break: break-all;
    max-width: 230px;
    font-size: 0.8em;
    font-family: Consolas, Courier, monospace;
  }

  .extra {
    max-width: 500px;
    word-break: break-word;
  }

  .description {
    max-width: 300px;
    word-break: break-word;
  }
}


table.audit-trail thead,
table.audit-trail tfoot {
  background-color: white;
  position: sticky;
}

table.audit-trail thead {
  inset-block-start: 0;
  /* "top" */
}

table.audit-trail tfoot {
  inset-block-end: 0;
  /* "bottom" */
}

.hmi-component.audit_trails {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .container-fluid {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}
