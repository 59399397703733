.duration-input-group {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 1em;

  .form-control {
    width: 2em;
    padding-left: .5em;
    padding-right: .5em;
    display: inline-block;
  }

  [type=number] {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    text-align: right;
    flex: 1;
    margin-right: .3em;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .subgroup {
    display: flex;
    align-items: baseline;
    flex: 1;

    &.switch {
      flex: 0 auto;
    }
  }
}
