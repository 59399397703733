@import "project_images";
@import "duration_input";

.multi-check-box-form-content > div {
  display: flex;
}
.multi-check-box-form-content .form-check{
  margin-right: 20px;
}

.multi-check-box-form-vertical > div {
  display: flex;
  flex-direction: column;
}
.multi-check-box-form-vertical .form-check{
  margin-top: 5px;
}
.normal-input{
  display: inline-block !important;
  width: auto;
}
.variable-edit-form-div{
  width: 60%;
  background-color: white;
  position: fixed;
  top: 20%;
  padding: 30px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  left: 20%;
  .close-form{
    color: black !important;
    text-decoration: none;
    cursor: pointer;
    padding: 8px;
    float: right;
  }
}

.help-cursor {
  cursor: help;
}
